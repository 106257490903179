<script land="ts" setup>
import bellDefault from '@/assets/svg/24/bell-default.svg';

const { user } = useUserStore();
</script>

<template>
  <header class="o-header">
    <div class="container">
      <div class="row">
        <NuxtLink class="avatar" to="/profile">
          <img :src="user.image" alt="" />
        </NuxtLink>
        <div class="user-name">{{ user.login }}</div>
        <button class="action">
          <component :is="bellDefault" />
        </button>
      </div>
    </div>
  </header>
</template>

<style lang="scss">
.o-header {
  padding: 16px 0;
  .row {
    display: flex;
    align-items: center;
  }
  .avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #fff;
    box-sizing: border-box;
    img {
      //   max-width: 100%;
      max-height: 100%;
    }
  }
  .user-name {
    padding: 0 10px;
  }
  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>
